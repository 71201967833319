<template>
  <div class="preview dark"
    v-loading="loading"
    element-loading-text="加载中..."
    :element-loading-spinner="svg"
    element-loading-svg-view-box="-10, -10, 50, 50"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="preview-header">
      <div class="breadcrumb-head">
        <el-breadcrumb separator="/" >
          <el-breadcrumb-item><span style="cursor: pointer;color: #1eaaff;">用户管理</span></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="preview-body2">
      <el-scrollbar>
        <div class="frame common-frame">
          <div class="line"
               style="padding:24px 0 16px">
            <div class="left">
                <el-input v-model="conf.keyword" placeholder="请输入关键字" />
            </div>
            <el-button type="primary" style="margin-left:10px;"
                         @click="getDataFn">搜索</el-button>
          </div>
          <el-table :data="tableData"
                    style="width: 100%">
            <el-table-column label="头像" prop="head" min-width="10%">
              <template #default="scope">
                <el-image
                  style="width: 50px; height: 50px; margin-bottom:-4px"
                  :preview-src-list="[scope.row.head]"
                  :src="scope.row.head"
                >
                </el-image>
              </template>
            </el-table-column>
            <el-table-column label="昵称"
                             prop="nickname"
                             min-width="10%"></el-table-column>
            <el-table-column label="手机号"
                             prop="phone"
                             min-width="20%" />
            <el-table-column label="空间数目"
                             prop="spaceCount"
                             min-width="15%" />
            <el-table-column label="项目数据"
                             prop="prjCount"
                             min-width="15%" />
            <el-table-column label="版本数据"
                             prop="verNum"
                             min-width="10%" />
            <el-table-column label="快照数据"
                             prop="snatNum"
                             min-width="10%" />
            <el-table-column label="操作"
                             min-width="10%">
              <template #default="scope">
                <el-button link
                           size="small"
                           @click.prevent="showAdjust(tableData[scope.$index])">
                  调整
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="line pd-10">
            <page :total="conf.total" v-show="tableData.length" @onChange="onChange"></page>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <window windowId="edit-data-source"
            :shadow="true"
            class="dark"
            :title="userInfo.title"
            @windowHide="hideAdjust"
            v-show="userInfo.isShow">
      <template #body>
        <div class="line pd-20">
          <el-form ref="form" :model="userInfo.info" :rules="rules">
            <el-form-item prop="name">
               <div class="insert-h">
                  <div class="attr">空间数量</div>
                  <div class="val">
                    <el-input v-model="userInfo.info.spaceNum"
                              placeholder="请输入参数名" />
                  </div>
                </div>
            </el-form-item>
            <el-form-item prop="alaise">
                 <div class="insert-h">
                    <div class="attr">项目数量</div>
                    <div class="val">
                      <el-input v-model="userInfo.info.prjNum"
                                placeholder="请输入变量值" />
                    </div>
                  </div>
            </el-form-item>
            <el-form-item prop="alaise">
                 <div class="insert-h">
                    <div class="attr">版本数量</div>
                    <div class="val">
                      <el-input v-model="userInfo.info.verNum"
                                placeholder="请输入变量值" />
                    </div>
                  </div>
            </el-form-item>
            <el-form-item prop="alaise">
                 <div class="insert-h">
                    <div class="attr">快照数量</div>
                    <div class="val">
                      <el-input v-model="userInfo.info.snatNum"
                                placeholder="请输入变量值" />
                    </div>
                  </div>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <template #footer>
        <div class="line pd-20 align-right">
          <el-button @click="hideAdjust()">取消</el-button>
          <el-button type="primary"
                     @click="adjustNum()">保存</el-button>
        </div>
      </template>
    </window>
  </div>
</template>

<script>
import Window from 'components/common/forms/Window';
import Page from 'components/common/forms/Page';
import Empty from 'components/common/forms/Empty';
export default {
  name: 'UserManagement',
  components: {
    Window,
    Page,
    Empty
  },
  data () {
    return {
      loading: true,
      rules: {
        spaceNum: [{required: true, message: '空间数量不能为空', trigger: 'blur'}],
        prjNum: [{required: true, message: '项目数量不能为空', trigger: 'blur'}],
      },
      project: {},
      tableData: [],
      multipleSelection: [],
      userInfo: {
        isShow: false,
        title: "",
        info: {
          id: '',
          spaceNum: 10,
          prjNum: 10
        }
      },
      conf: {
        keyword: '',
        pageSize: 10,
        pageNum: 1,
        total: 10
      },
    }
  },
  mounted() {
    this.getDataFn();
  },
  methods: {
    getDataFn() {
      let self = this;
      self.$api.sp.searchUserInfos({
        keyword: self.conf.keyword,
        pageNum: self.conf.pageNum,
        pageSize: self.conf.pageSize
      }).then((res) => {
        self.tableData = res.list;
        self.tableData.forEach(v => {
          v.head = v.icon == -1 ? require('assets/images/frame/default-head.svg') : '/file/'+v.icon;
          v.spaceCount = v.createSpaceNum + '/' + v.spaceNum;
          v.prjCount = v.createPrjNum + '/' + v.prjNum;
        })
        self.conf.total = res.total;
        self.loading = false;
      })
    },
    onChange (param) {
      this.conf.pageSize = param.pageSize;
      this.conf.pageNum = param.currentPage;
      this.getDataFn();
    },
    initForm () {
      this.$refs.form.clearValidate();
    },
    showAdjust(obj) {
      this.userInfo.info = {...obj};
      this.userInfo.title = '调整限制数量';
      this.userInfo.isShow = true;
    },
    hideAdjust() {
      this.userInfo.isShow = false;
    },
    adjustNum() {
      let self = this;
      let args = {...self.userInfo.info}
      self.$api.auth.adjustUserLimitNum(args).then(data => {
        self.$message.success( "调整成功!");
        self.getDataFn();
        self.hideAdjust();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>